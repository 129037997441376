import './Prom.scss';
import { useState, useEffect } from 'react';
import logo from '../../assets/say.svg';
import { Link } from 'react-router-dom';

export default function Prom() {
    const [type, setType] = useState(true); // templates or stickers

    const [a4count, setA4count] = useState(0);
    const [a3count, setA3count] = useState(0);
    const [a2count, setA2count] = useState(0);
    const [a1count, setA1count] = useState(0);

    const [templateSalary, setTemplateSalary] = useState('?');

    const [sa4count, setSA4count] = useState(0);
    const [sa3count, setSA3count] = useState(0);
    const [sa2count, setSA2count] = useState(0);
    const [sa1count, setSA1count] = useState(0);

    const [stickerSalary, setStickerSalary] = useState('?');

    // Handle input change with validation for numbers
    const handleInputChange = (e, setState) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
        setState(Number(value));
        }
    };

    // Calculate total income
    const calculateIncome = () => {
        const total =
        a4count * 80 + a3count * 100 + a2count * 200 + a1count * 300;
        setTemplateSalary(total);
    };

    // Calculate total income 2
    const calculateIncome2 = () => {
        const total =
        sa4count * 30 + sa3count * 40 + sa2count * 50 + sa1count * 60;
        setStickerSalary(total);
    };

    return (
        <div className="Prom">
            <div className="wrapper">
                <div className="pags">
                    <Link to='/deliver'>Закладчик</Link>
                    <Link to='/chem'>Химик</Link>
                    <Link to='/rider'>Перевозчик</Link>
                    <Link className='selected' to='/prom'>Промоутер</Link>
                </div>
                <img src={logo} alt="logo" />
                <h2>Узнай свой доход</h2>
                <div className="content">
                    <div className="btns">
                        <button className={type ? 'selected' : ''} onClick={() => setType(true)}>Трафареты</button>
                        <button className={!type ? 'selected' : ''} onClick={() => setType(false)}>Стикеры</button>
                    </div>
                    {type && <div className="templates">
                        <div className="row">
                            <button>Формат</button>
                            <button>Количество</button>
                        </div>
                        <div className="row">
                            <button>A4</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={a4count}
                                onChange={(e) => handleInputChange(e, setA4count)}
                            />
                        </div>
                        <div className="row">
                            <button>A3</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={a3count}
                                onChange={(e) => handleInputChange(e, setA3count)}
                            />
                        </div>
                        <div className="row">
                            <button>A2</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={a2count}
                                onChange={(e) => handleInputChange(e, setA2count)}
                            />
                        </div>
                        <div className="row">
                            <button>A1</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={a1count}
                                onChange={(e) => handleInputChange(e, setA1count)}
                            />
                        </div>
                    </div>}
                    {!type && <div className="templates">
                        <div className="row">
                            <button>Формат</button>
                            <button>Количество</button>
                        </div>
                        <div className="row">
                            <button>1/8 листа A4</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={sa4count}
                                onChange={(e) => handleInputChange(e, setSA4count)}
                            />
                        </div>
                        <div className="row">
                            <button>1/4 листа А4</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={sa3count}
                                onChange={(e) => handleInputChange(e, setSA3count)}
                            />
                        </div>
                        <div className="row">
                            <button>1/2 листа А4</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={sa2count}
                                onChange={(e) => handleInputChange(e, setSA2count)}
                            />
                        </div>
                        <div className="row">
                            <button>Лист А4</button>
                            <input
                                type="text"
                                placeholder="Кол-во"
                                value={sa1count}
                                onChange={(e) => handleInputChange(e, setSA1count)}
                            />
                        </div>
                    </div>}
                    <span>Минимальная дистанция между рисунками 50 метров</span>
                    {type && <div className="resultInfo">
                        <span>{templateSalary} руб.</span>
                    </div>}
                    {!type && <div className="resultInfo">
                        <span>{stickerSalary} руб.</span>
                    </div>}
                    {type && <button className="result" onClick={calculateIncome}>
                        Рассчитать доход
                    </button>}
                    {!type && <button className="result" onClick={calculateIncome2}>
                        Рассчитать доход
                    </button>}
                </div>
            </div>
        </div>
    )
}