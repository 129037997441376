import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main/Main.jsx';
import Chem from './pages/Chem/Chem.jsx';
import Rider from './pages/Rider/Rider.jsx';
import Prom from './pages/Prom/Prom.jsx';
import Welcome from './pages/Welcome/Welcome.jsx';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Welcome/>} />
          <Route path='/deliver' element={<Main/>} />
          <Route path='/chem' element={<Chem/>} />
          <Route path='/rider' element={<Rider/>} />
          <Route path='/prom' element={<Prom/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
