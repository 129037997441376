import './Welcome.scss';
import { useNavigate } from 'react-router-dom';

export default function Welcome() {
    const navigate = useNavigate();

    const sendIP = async () => {
        try {
            const response = await fetch("https://say48.ai/api/post-visit/", {
                method: "GET",
            });
            if (!response.ok) {
                throw new Error(`HTTP status ${response.status}`);
            }
        } catch (error) {
            console.error("Error sending request:", error);
        } finally {
                console.log("Navigating to main page...");
                navigate('deliver/');
        }
    };

    return (
        <div className="Welcome">
            <div className="wrapper">
                <h2>Добро пожаловать!</h2>
                <button onClick={sendIP}>Перейти к калькулятору</button>
            </div>
        </div>
    );
}
