import './Chem.scss';
import { useState, useEffect } from 'react';
import logo from '../../assets/say.svg';
import { Link } from 'react-router-dom';

export default function Chem() {
    const [type, setType] = useState(true); // true мука false кристалл
    const [mukaCount, setMukaCount] = useState(0);
    const [krystalCount, setKrystalCount] = useState(0);

    const [templateSalary, setTemplateSalary] = useState('?');
    const [templateSalary2, setTemplateSalary2] = useState('?');

    // Handle input change with validation for numbers
    const handleInputChange = (e, setState) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
        setState(Number(value));
        }
    };

    // Calculate total income
    const calculateIncome = () => {
        const total =
        100000 * mukaCount;
        setTemplateSalary(total);
    };

    // Calculate total income
    const calculateIncome2 = () => {
        const total =
        150000 * krystalCount;
        setTemplateSalary2(total);
    };



    return (
        <div className="Chem">
            <div className="wrapper">
                <div className="pags">
                    <Link to='/deliver'>Закладчик</Link>
                    <Link className='selected' to='/chem'>Химик</Link>
                    <Link to='/rider'>Перевозчик</Link>
                    <Link to='/prom'>Промоутер</Link>
                </div>
                <img src={logo} alt="logo" />
                <h2>Узнай свой доход</h2>
                <div className="content">
                    <div className="left">
                        <div className="btns">
                            <button className={type ? 'selected' : ''} onClick={() => setType(true)}>Мука</button>
                            <button className={!type ? 'selected' : ''} onClick={() => setType(false)}>Кристалл</button>
                        </div>
                        {type && <div className="templates">
                            <div className="row">
                                <button>Килограмм</button>
                                <input
                                    type="text"
                                    placeholder="Кол-во"
                                    value={mukaCount}
                                    onChange={(e) => handleInputChange(e, setMukaCount)}
                                />
                            </div>
                        </div>}
                        {type && <div className="resultInfo">
                            <span>{templateSalary} руб.</span>
                        </div>}
                        {type && <button className="result" onClick={calculateIncome}>
                            Рассчитать доход
                        </button>}
                        {!type && <div className="templates">
                            <div className="row">
                                <button>Килограмм</button>
                                <input
                                    type="text"
                                    placeholder="Кол-во"
                                    value={krystalCount}
                                    onChange={(e) => handleInputChange(e, setKrystalCount)}
                                />
                            </div>
                        </div>}
                        {!type && <div className="resultInfo">
                            <span>{templateSalary2} руб.</span>
                        </div>}
                        {!type && <button className="result" onClick={calculateIncome2}>
                            Рассчитать доход
                        </button>}
                    </div>
                    <div className="info">
                    Процесс варки занимает в среднем 10 часов до мокрой муки, за это время необходимо : собрать оборудование, сделать 3 основных этапа + промывка товара, постоянный контроль не требуется, лишь контролирование температуры и скорости прилива прекурсора в соответствии с технологической задачей.<br/><br/>

По сути за десять часов, несколько незамысловатых действий, и получаем продукт который оставляем на сушку, затем кристализируем.<br/><br/>

Работая 5 дней в неделю с понедельника по пятницу мы можем сварить 8,5кг муки. <br/><br/>
Так как кристаллизация проводится отдельно от варки и сама кристаллизация не требует постоянного контроля и протекает сама по себе , мы можем параллельно поставить  муку на кристаллы. <br/><br/>
Полный цикл кристаллизации из муки в кристаллы занимает 3 дня.<br/><br/>
Готовую муку которую мы можем поставить на кристаллизацию на следующие сутки после первой варки  <br/><br/>

Есть два варианта развития событий:<br/><br/>
1. Ставить муку на кристаллизацию по мере сваренной муки. Поставив варку в понедельник , в четверг уже будут готовы кристаллы <br/><br/>
2. Варить муку 5 дней и потом весь сваренный объем разом поставить на кристаллизацию , то есть в пятницу поставить все 8,5 кг муки на кристаллы и к понедельнику все кристаллы будут готовы.<br/><br/>

И это лишь на одной колбе, самый простой процесс! 😉<br/><br/>

Параллельно можно работать даже на 5 колбах!<br/><br/>

<b>Делаешь 50кг/месяц , премия 1.000.000р</b><br/>
<b>Делаешь 100кг/1 месяц премия 3.000.000р</b><br/>

Простая математика при наличии дома и комнаты в 20 квадратов + дымоход(камин ) - при работе на колбах через систему Технолога ежедневной работы в 5 колб по 20 литров, за неделю можно собрать 70 кг муки, то есть работая 15 дней в месяц можно получить спокойно 100кг товара.<br/><br/>

<b>Так же приятный бонус после 30 сваренных кг выделяем 1.000.000-1.500.000 рублей на покупку дома.</b>
                    </div>
                </div>
            </div>
        </div>
    )
}