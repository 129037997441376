import './Rider.scss';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/say.svg';

export default function Rider() {
    const [km, setKm] = useState(0);
    const [weight, setWeight] = useState(0);
    const [preResult, setPreResult] = useState('?');

    const [weightOfPack, setWightOfPack] = useState(0);

    const [arrayOfVars, setArrayOfVars] = useState([]);

    const [minKm, setMinKm] = useState(0);
    const [maxKm, setMaxKm] = useState(3000);

    const handleKmSelect = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
            if (value >= minKm && value <= maxKm) {
                setKm(value);
            }
        }
    };

    const handleWeightOfPack = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
            setWightOfPack(value);
        }
    };

    useEffect(() => {
        setKm(0);
    }, [weight])

    const fases = [10, 20, 30, 40, 50, 100, 150, 200, 250, 300, 500, 1000, 1500];
    const prices = [1800, 2000, 2500, 3000, 3500, 4500, 5500, 6000, 6500, 7000, 7500, 13000, 17000];

    function findCombinations(weightOfPack, fases, prices) {
        const results = [];
    
        // Округляем weightOfPack до ближайшего числа, кратного 10
        weightOfPack = Math.round(weightOfPack / 10) * 10;
    
        // Определяем допустимые значения fases в зависимости от диапазона weightOfPack
        let allowedFases;
        if (weightOfPack < 200) {
            allowedFases = [10];
        } else if (weightOfPack < 300) {
            allowedFases = [20, 10];
        } else if (weightOfPack < 400) {
            allowedFases = [30, 20, 10];
        } else if (weightOfPack < 500) {
            allowedFases = [40, 30, 20, 10];
        } else if (weightOfPack < 1000) {
            allowedFases = [50, 100, 150, 200];
        } else if (weightOfPack < 3000) {
            allowedFases = [100, 150, 200, 250, 300, 500, 1000];
        } else {
            allowedFases = [100, 150, 200, 250, 300, 500, 1000, 1500];
        }
    
        // Функция для рекурсивного нахождения всех комбинаций
        function backtrack(target, combination, startIndex) {
            if (target === 0) {
                results.push([...combination]); // Если сумма равна weight, сохраняем комбинацию
                return;
            }
    
            for (let i = startIndex; i < allowedFases.length; i++) {
                if (allowedFases[i] <= target) {
                    combination.push(allowedFases[i]);
                    backtrack(target - allowedFases[i], combination, i); // Разрешаем повторы
                    combination.pop();
                }
            }
        }
    
        // Найти все комбинации
        backtrack(weightOfPack, [], 0);
    
        // Вычисляем сумму prices и среднее значение fases для каждой комбинации
        const combinationsWithMetrics = results.map(combination => {
            const cost = combination.reduce((sum, weight) => {
                const index = fases.indexOf(weight);
                return sum + prices[index];
            }, 0);
    
            const averageFases = combination.reduce((sum, weight) => sum + weight, 0) / combination.length;
    
            return { combination, cost, averageFases };
        });
    
        // Сортируем комбинации по среднему значению fases в убывающем порядке
        combinationsWithMetrics.sort((a, b) => b.averageFases - a.averageFases);
    
        // Возвращаем топ-3 комбинации с наибольшим средним значением fases
        const topCombinations = combinationsWithMetrics.slice(0, 3).map(item => ({
            combination: item.combination,
            totalCost: item.cost,
        }));
    
        // Возвращаем результат (замените setArrayOfVars на нужное действие, например return)
        console.log(topCombinations);
        setArrayOfVars(topCombinations);
    }    

    return (
        <div className="Rider">
            <div className="wrapper">
                <div className="pags">
                    <Link to='/deliver'>Закладчик</Link>
                    <Link to='/chem'>Химик</Link>
                    <Link className='selected' to='/rider'>Перевозчик</Link>
                    <Link to='/prom'>Промоутер</Link>
                </div>
                <img src={logo} alt="logo" />
                <h2>Узнай свой доход</h2>
                <div className="content">
                    <span>1. Выбери вес, который повезещь с собой</span>
                    <div className="btns">
                        <button onClick={() => {setWeight(60); setMaxKm(350)}} className={weight === 60 ? 'selected' : ''}>От 50 до 500 гр.</button>
                        <button onClick={() => {setWeight(80); setMaxKm(500)}} className={weight === 80 ? 'selected' : ''}>От 500 до 1000 гр.</button>
                        <button onClick={() => {setWeight(100); setMaxKm(1000)}} className={weight === 100 ? 'selected' : ''}>От 1000 до 3000 гр.</button>
                        <button onClick={() => {setWeight(120); setMaxKm(3000)}} className={weight === 120 ? 'selected' : ''}>От 3000 до 5000 гр.</button>
                        <button onClick={() => {setWeight(140); setMaxKm(3000)}} className={weight === 140 ? 'selected' : ''}>От 5000 до 7000 гр</button>
                        <button onClick={() => {setWeight(160); setMaxKm(3000)}} className={weight === 160 ? 'selected' : ''}>От 7000 до 10000 гр.</button>
                        <button onClick={() => {setWeight(180); setMaxKm(3000)}} className={weight === 180 ? 'selected' : ''}>От 10000 гр. и выше</button>
                    </div>
                    <span>2. Выбери сколько км будет твой маршрут {`от ${minKm} км до ${maxKm} км`}</span>
                    <input 
                        type="text" 
                        value={km} 
                        onChange={handleKmSelect} 
                        placeholder="Введите километры" 
                    />
                    <div className="preCount">
                        <button className='mini' onClick={() => setPreResult(km * weight)}>Сколько я получу уже за перевозку?</button>
                        <span>{preResult} рублей отдельно за перевозку</span>
                    </div>
                    <span>3. Введи точный вес который повезешь (гр)</span>
                    <input 
                        type="text" 
                        value={weightOfPack} 
                        onChange={handleWeightOfPack} 
                        placeholder="Введите вес который повезете (гр)" 
                    />
                    <button className='mini' onClick={() => findCombinations(Math.round(weightOfPack / 10) * 10, fases, prices)}>Показать лучшие варианты фасовок</button>
                    <div className="variant-list">
                        {arrayOfVars.map((item, index) => (
                            <div key={index} className="variant">
                                <div className="left">
                                    {item.combination.map((value, i) => (
                                    <div key={i} className="cell">
                                        {value}
                                    </div>
                                    ))}
                                </div>
                                <div className="right">
                                    {item.totalCost}
                                </div>
                            </div>
                        ))}
                    </div>
                    {(weight !== 0 && km !== 0 && weightOfPack !== 0) && <div className="info">
                        <div className="part">
                            <h4>{arrayOfVars.length !== 0 && (arrayOfVars[0]['totalCost'] + preResult)}</h4>
                            <span>Суммарный заработок за поездку</span>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    )
}